import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '@app/shared/shared.module';
import {RouterModule} from '@root/node_modules/@angular/router';
import { LoginComponent } from '@app/onboarding/login/login.component';
import { LoginRoutes } from '@app/onboarding/login/login.routing';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [LoginComponent],
  imports: [
    RouterModule.forChild(LoginRoutes),
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class LoginModule { }
