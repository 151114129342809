import { Component, OnInit } from '@angular/core';
import { ItemsServiceService } from '@app/core/items-service.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  image: string;
  id: string;
  key: string;
  isAdmin = false;

  constructor(
    private itemService: ItemsServiceService,
    private imageService: ItemsServiceService,
    public modalRef: BsModalRef,
    private router: Router) { }

  ngOnInit(): void {
    this.id = localStorage.getItem('itemId');
    const user = localStorage.getItem('user');
    if (user && user !== 'null') {
      this.isAdmin = true;
    }
  }

  deletePicture(): void {
    this.itemService.deleteImage(this.id).then(() => {
      this.modalRef.hide();
    });
  }

  editPicture() {
    this.modalRef.hide();
    this.router.navigate(['admin', this.id]).then();
  }
}
