import {NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FeaturesComponent} from '@app/features/features.component';
import { AuthGuard } from '@app/core/auth.guard';

const appRoutes: Routes = [
  { path: '', component: FeaturesComponent },
  { path: 'about-me', loadChildren: () => import('./features/components/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'contact', loadChildren: () => import('./features/components/contact/contact.module').then(m => m.ContactModule) },
  { path: 'gallery', loadChildren: () => import('./features/components/pictures-galery/pictures-galery.module').then(m => m.PicturesGalleryModule) },
  { path: 'gallery', loadChildren: () => import('./features/components/pictures-galery/pictures-galery.module').then(m => m.PicturesGalleryModule) },
  { path: 'admin', loadChildren: () => import('./features/admin/admin-platform.module').then(m => m.AdminPlatformModule), canActivate: [AuthGuard]},
  { path: 'admin:id', loadChildren: () => import('./features/admin/admin-platform.module').then(m => m.AdminPlatformModule), canActivate: [AuthGuard]},
  { path: 'login', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)},
  {
    path: '**',
    loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
