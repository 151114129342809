import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@app/core/auth.service";
import {BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  addUserForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.addUserForm = this.fb.group({
      userName: ['', Validators.required],
      userEmail: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  addUser(form): void {
    this.auth.createUser(form).then((user) => {
      this.modalService.hide(1);
    });
  }
}
