import { Route } from '@angular/router';
import { AdminPlatformComponent } from '@app/features/admin/admin-platform.component';

export const AdminPlatformRoutes: Route[] = [
  {
    path: '',
    component: AdminPlatformComponent
  },
  {
    path: ':id',
    component: AdminPlatformComponent
  }
];
