<nav class="navbar navbar-expand-lg navbar-light">
  <a class="navbar-brand" href="#">
    <i class="fab fa-pied-piper-alt" (click)="goToHome()"></i>
  </a>
  <button class="navbar-toggler" type="button"
          (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end"
       [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav pt-3">
      <li class="nav-item">
        <p class="mr-5" (click)="goToAbout()">Acasa</p>
      </li>
      <li class="nav-item">
        <p class="mr-5" (click)="goToPictures()">Galerie Lucrari</p>
      </li>
      <li class="nav-item">
        <p class="mr-5" (click)="goToContact()">Contact</p>
      </li>
      <li class="nav-item" *ngIf="isAdmin">
        <p class="mr-5" (click)="goToAdmin()">Admin</p>
      </li>
      <li class="nav-item" *ngIf="isAdmin">
        <button class="btn btn-sm btn-danger" (click)="logOut()">Logout</button>
      </li>
    </ul>
  </div>
</nav>
