export class Upload {
  id: number;
  file: File;
  name: string;
  url: string;

  constructor(file: File) {
    this.file = file;
  }
}
