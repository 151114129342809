import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminPlatformComponent} from './admin-platform.component';
import {RouterModule} from "@angular/router";
import {SharedModule} from "@app/shared/shared.module";
import {AdminPlatformRoutes} from "@app/features/admin/admin-platform.routing";
import {ReactiveFormsModule} from "@angular/forms";
import {PicturesArticleComponent} from "@app/features/admin/components/pictures-article/pictures-article.component";
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [AdminPlatformComponent, PicturesArticleComponent],
  imports: [
    RouterModule.forChild(AdminPlatformRoutes),
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    AdminPlatformComponent,
    PicturesArticleComponent]
})
export class AdminPlatformModule {
}
