import { Component, OnInit } from '@angular/core';
import {Router} from '@root/node_modules/@angular/router';
import { AuthService } from '@app/core/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;
  isAdmin = false;

  constructor(
    private router: Router,
    private auth: AuthService) { }

  ngOnInit() {
    const user = localStorage.getItem('user');
    if (user && user !== 'null') {
      this.isAdmin = true;
    }
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  goToAbout(): void {
    this.router.navigate(['']).then();
  }

  goToHome() {
    this.router.navigate(['']).then();
  }

  goToContact(): void {
    this.router.navigate(['contact']).then();
  }

  goToAdmin(): void {
    this.router.navigate(['admin']).then();
  }

  goToPictures() {
    this.router.navigate(['gallery'])
  }

  logOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['']);
    });
  }
}
