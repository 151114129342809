<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="modal-body">
        <p>Töltse ki az alábbi adatokat:</p>
        <form [formGroup]="addUserForm" (submit)="addUser(addUserForm.value)">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Felhasználó neve</span>
            </div>
            <input type="text" class="form-control" formControlName="userName">
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Felhasználó email címe</span>
            </div>
            <input type="text" class="form-control" formControlName="userEmail">
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Jelszó</span>
            </div>
            <input type="password" class="form-control" formControlName="password">
          </div>
          <button class="btn btn-sm btn-outline-info" type="submit">Mentés</button>
        </form>
      </div>
    </div>
  </div>
</div>
