<app-loader></app-loader>
<div class="container pictures-container">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-lg p-3 mb-5 bg-white rounded">
        <div class="card-header">
          <div class="row d-flex justify-content-between">
            <div>
              <p>Clickeljen ide egy új kép hozzá adásához</p>
            </div>
            <div>
              <button class="mr-1 btn btn-outline-info btn-sm" (click)="addUser()">Új fehasználó</button>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <img [src]="imagePlaceHolder">
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="addNewItem">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Kép címe</span>
              </div>
              <input type="text" class="form-control" formControlName="title">
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Leírás</span>
              </div>
              <input type="text" class="form-control" formControlName="description">
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Kategória</span>
              </div>
              <div class="custom-file">
                <ng-select
                  class="w-75"
                  formControlName="categories"
                  [multiple]="false"
                  [items]="categories"></ng-select>
              </div>
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="custom-file">
                <input
                  (change)="addImage($event)"
                  type="file" class="custom-file-input"
                  formControlName="image">
                <label class="custom-file-label">Válassza ki a képet</label>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <button
                (click)="saveItem(addNewItem)"
                class="mr-2 btn btn-outline-info btn-sm"
                [disabled]="!addNewItem.valid"
                *ngIf="!existingPicture">Kép hozzá adás
              </button>
              <button
                class="mr-2 btn btn-outline-info btn-sm"
                *ngIf="existingPicture" (click)="updateItem(addNewItem)">Modositás
              </button>
              <button class="mr-2 btn btn-outline-success btn-sm" (click)="navigateToGallery()">Galéria</button>
              <button class="btn btn-outline-danger btn-sm" (click)="logOut()">Logout</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
