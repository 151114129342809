import { Injectable } from '@angular/core';
import { Item } from '@app/core/models/item.model';
import { Upload } from '@app/core/models/upload.model';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsServiceService {

  constructor(
    private _afs: AngularFirestore,
    private _storage: AngularFireStorage) {
  }

  async filterData(category: string): Promise<firebase.firestore.DocumentData[]> {
    const snapshot = await firebase.firestore().collection('items').where('category', '==', category.toLowerCase()).get();
    return snapshot.docs.map(doc => doc.data());
  }

  getAllItems(): Observable<Array<Item>> {
    return this._afs.collection<Item>('items').valueChanges();
  }

  updateItemById(form: any, id: string): Promise<any> {
    const formValue = form.value;
    const newValueOfItem = {
      title: formValue.title,
      description: formValue.description,
      category: formValue.categories
    };
    return this._afs.collection('items').doc(id).update(newValueOfItem).then();
  }

  async getItemById(docId: string) {
    let document = await this._afs.collection('items').doc(docId).get().toPromise();
    return document.data();
  }

  uploadImage(path: string, upload: Upload): AngularFireUploadTask {
    const id = new Date().getTime();
    const storageRef = this._storage.ref(`${path}/${id}`);
    const metadata = {
      customMetadata: {
        originalName: upload.file.name
      }
    };
    return storageRef.put(upload.file, metadata);
  }

  createNewItem(item: Item): Promise<any> {
    const newItem = JSON.parse(JSON.stringify(item));
    const productsList = this._afs.collection('items');
    return productsList.add(newItem).then((data: any) => {
      return this.updateItemId('items', data.id);
    });
  }

  updateItemId(path: string, id: string): void {
    this._afs.doc(`${path}/${id}`).update({
      id
    }).then(() => {
    });
  }

  async deleteImage(itemKey: string) {
    this._afs.collection('items').doc(itemKey).delete().then(() => {
      this.getAllItems();
    });
  }
}
