<app-header></app-header>
<div class="container-fluid about-container">
  <div class="card shadow-lg p-3 bg-white rounded">
    <div class="card-body">
      <div class="row">
        <div class="p-5 red-border col-5">
          <p>Sunt o persoana cu dizabilitati, amator de arta, care cauta sa isi exprime trairile sufletesti prin Picturi si Desene.
          In Galeria mea puteti vizualiza unele dintre creatiile mele. Pentru pareri critici va astept pe pagina de Contact</p>
          <i class="fab fa-facebook"></i>
          <i class="fab fa-skype"></i>
          <i class="fab fa-instagram"></i>
          <i class="fab fa-pinterest-p"></i>
        </div>
        <div class="col-7 align-content-center">
          <img src="../../../../assets/img/mami.jpg" alt="Avatar">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
