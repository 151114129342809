import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesComponent } from './features.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import {AboutUsModule} from '@app/features/components/about-us/about-us.module';
import {AdminPlatformModule} from "@app/features/admin/admin-platform.module";

@NgModule({
  declarations: [
    FeaturesComponent,
    HomeComponent
  ],
  exports: [
    FeaturesComponent
  ],
  imports: [
    AboutUsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    AdminPlatformModule
  ]
})
export class FeaturesModule { }
