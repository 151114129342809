import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from "@app/core/loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  private loader;

  showLoader: boolean;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.loader = this.loaderService.status.subscribe((isShown: boolean) => {
      this.showLoader = isShown;
    });
  }

  ngOnDestroy(): void {
    this.loader.unsubscribe();
  }
}
