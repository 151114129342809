<div class="custom-modal-content custom-image-modal">
  <div class="modal-body">
    <img [src]="image" alt="">
  </div>
  <div class="modal-footer">
    <button class="btn btn-icon-only">
      <i class="fas fa-thumbs-up"></i>
    </button>
    <button class="btn btn-icon-only">
      <i class="fab fa-instagram"></i>
    </button>
    <button class="btn btn-icon-only">
      <i class="fab fa-pinterest-p"></i>
    </button>
    <button *ngIf="isAdmin" class="btn btn-icon-only" (click)="deletePicture()">
      <i class="fas fa-trash"></i>
    </button>
    <button *ngIf="isAdmin" class="btn btn-icon-only" (click)="editPicture()">
      <i class="fas fa-edit"></i>
    </button>
  </div>
</div>
