import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@app/shared/base/base.module';
import { ImageModalComponent } from './modals/image-modal/image-modal.component';
import { AddUserComponent } from './modals/add-user/add-user.component';
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [ImageModalComponent, AddUserComponent],
  imports: [
    CommonModule,
    BaseModule,
    ReactiveFormsModule
  ],
  exports: [
    BaseModule
  ]
})
export class SharedModule {
}
