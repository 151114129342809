<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-lg mt-5 p-3 mb-5 bg-white rounded">
        <div class="card-header">
          <h6>Please Login</h6>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm" (submit)="login(loginForm)">
            <input class="form-control mb-1" formControlName="email" type="email">
            <input class="form-control mb-1" formControlName="password" type="password">
            <button class="btn btn-outline-danger btn-block">Login</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
