export interface ItemModel {
  id: string;
  title: string;
  description: string;
  image: ImageModel;
  category: string;
}

export interface ImageModel {
  imageId: string,
  name: string,
  url: string
}

export class Image implements ImageModel {
  imageId = '';
  name = '';
  url = '';

  constructor(imageDTO?: Image) {
    if (!imageDTO) {
      return;
    }
    this.imageId = imageDTO.imageId;
    this.name = imageDTO.name;
    this.url  = imageDTO.url;
  }

}

export class Item implements ItemModel {
  id = '';
  title = '';
  description = '';
  image = new Image();
  category= '';

  constructor(dto?: ItemModel) {
    if (!dto) {
      return;
    }
    this.id = dto.id ? dto.id : this.id;
    this.title = dto.title;
    this.description = dto.description;
    this.image = new Image(dto.image);
    this.category = dto.category;
  }
}
