import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ItemsServiceService } from '@app/core/items-service.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@app/core/auth.service';
import { LoaderService } from '@app/core/loader.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ImageModalComponent } from '@app/shared/modals/image-modal/image-modal.component';
import { AddUserComponent } from '@app/shared/modals/add-user/add-user.component';
import { Item } from '@app/core/models/item.model';
import { Upload } from '@app/core/models/upload.model';
import { DocumentData } from '@angular/fire/firestore';

@Component({
  selector: 'app-pictures-article',
  templateUrl: './pictures-article.component.html',
  styleUrls: ['./pictures-article.component.scss']
})
export class PicturesArticleComponent implements OnInit {
  addNewItem: FormGroup;
  imagePlaceHolder = '/assets/img/placeholder-image.png';
  existingPicture = false;
  categories = ['picture', 'draw'
  ];
  modalRef: BsModalRef;
  modalConfig = {
    animated: true,
    class: 'modal-dialog-centered modal-xl'
  };
  item = new Item();
  itemId = '';

  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private itemService: ItemsServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService) {
  }

  ngOnInit() {
    this.getParamId();
    this.initForm();
  }

  private getParamId(): void {
    this.route.params.subscribe((params: Params): void => {
      this.itemId = params.id;
      if (!this.itemId) {
        return;
      }
      this.existingPicture = true;
      this.getItemById();
    });
  }

  getItemById(): void {
    this.itemService.getItemById(this.itemId).then((res: DocumentData) => {
      this.imagePlaceHolder = res.image.url;
      this.addNewItem.patchValue({
        title: res.title,
        description: res.description,
        categories: res.category
      });
    });
  }

  updateItem(form: FormGroup): void {
    this.loaderService.show();
    this.itemService.updateItemById(form, this.itemId).then(() => {
      this.router.navigate(['gallery']).then();
      this.loaderService.hide();
    });
  }

  initForm(): void {
    this.addNewItem = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: ['', Validators.required],
      categories: []
    });
  }

  addImage(event: any): void {
    this.loaderService.show();
    const file = event.target.files[0];
    const currentUpload: Upload = new Upload(file);
    this.itemService.uploadImage('item-images', currentUpload).then((data: any) => {
      this.item['image'].imageId = String(Date.now());
      this.item['image'].name = data.metadata.customMetadata.originalName;
      data.ref.getDownloadURL().then((url: string) => {
        this.item['image'].url = url;
        this.loaderService.hide();
      });
    });
  }

  saveItem(form: FormGroup): void {
    this.item.category = form.value.categories.toLowerCase();
    this.item.title = form.value.title;
    this.item.description = form.value.description;
    this.itemService.createNewItem(this.item)
      .then(() => {
        this.addNewItem.reset();
      });
  }

  navigateToGallery() {
    this.router.navigate(['gallery']);
  }

  logOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['']);
    });
  }

  addUser() {
    this.modalRef = this.modalService.show(AddUserComponent, this.modalConfig);
  }
}
