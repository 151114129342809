<div class="container-fluid home-container shadow-lg p-3 bg-white rounded h-100">
  <div class="row">
    <div class="col-sm-12 col-lg-6 col-md-12">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title">Dembrovszky Edit</h2>
          <p class="card-text">Pictor amator</p>
          <p class="card-text">Sunt o persoana cu dizabilitati, amator de arta, care cauta sa isi exprime trairile sufletesti prin Picturi si Desene.
                               In <a routerLink="/gallery">Galeria</a> mea puteti vizualiza unele dintre creatiile mele. Pentru pareri critici va astept pe pagina de <a routerLink="/contact">Contact</a>. Am pregatit si o inregistrare prin care sa ma cunoasteti mai bine.
          </p>
          <div class="row p-3">
            <iframe src="https://www.youtube.com/embed/-zf3Urkp4BI" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="row justify-content-end">
            <button class="btn mr-3 btn-danger" (click)="navigateToGallery()">Galerie Lucrari</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
