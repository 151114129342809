import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { LoginModule } from '@app/onboarding/login/login.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    LoginModule
  ],
  exports: []
})
export class OnboardingModule { }
