import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AboutUsComponent} from '@app/features/components/about-us/about-us.component';
import {RouterModule} from '@root/node_modules/@angular/router';
import {AboutUsRoutes} from '@app/features/components/about-us/about-us.routing';
import {SharedModule} from '@app/shared/shared.module';



@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    RouterModule.forChild(AboutUsRoutes),
    CommonModule,
    SharedModule
  ],
  exports: [AboutUsComponent]
})
export class AboutUsModule { }
